import React from "react";
import HeaterList from "../components/heater-list"
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero"

export default function Electric({ data, pageContext }) {
  return (
    <Layout>
    
      <SEO
        title="Free-standing Patio Heaters"
        keywords={[`free standing patio heaters`]}
        description="Free standing patio heaters - simple portable heat - explore the best free-standind heaters on the market"
      />

<Hero heading='Free-standing Patio Heaters' subheading='Simple portable heat' />

<div className="flex flex-wrap w-full container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
<div className="p-6">
<h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">The best free-standing patio heaters.</h3>
<p className="text-gray-600">We've hand-picked the top patio heaters on the market for you for explore.<br /><br /></p>
<HeaterList data={data.products} />
</div>
</div>

    </Layout>
  );
}

export const query = graphql`
  query {
    products: allGoogleSheetProductsRow(filter: {design: {eq: "Standing"}}) {
      edges {
        node {
          productImage
          productName
        }
      }
    }
  }
`